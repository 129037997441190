import React, { useState, useEffect } from 'react'
import "../styles/aboutus.css"

const PersonTab = props => {
    return (
        <div className="person">
            <span className="avatar">
                <img id="avatar" src={props.image} alt="Logo"></img>
            <span id="information">
                <ul>
                    <li><strong>Name: </strong> {props.name} </li>
                    <li><strong>Email: </strong> {props.email} </li>
                    <li><strong>Organization: </strong> {props.organization} </li>
                    <li><strong>Address: </strong> {props.address} </li>
                </ul>
            </span>
            </span>
        </div>
    )
}

export default PersonTab;