import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { Button } from '@material-ui/core';
import '../styles/axisX.css'

const colors = d3.scaleOrdinal(d3.schemeCategory10);
const format = d3.format(".2d");

const XAxis = ({ top, bottom, left, right, height, scale }) => {
  const axis = useRef(null);

  useEffect(() => {
    d3.select(axis.current).call(d3.axisBottom(scale));
    // d3.select(axis.current).text("Authors");
    // d3.select("#axisX").selectChildren("text").attr("transform", "rotate(-45");
  });

  return (
    <g
      className="axisX"
      ref={axis}
      transform={`translate(${left}, ${height - bottom})`}
    />
  );
};

const YAxis = ({ top, bottom, left, right, scale }) => {
  const axis = useRef(null);

  useEffect(() => {
    d3.select(axis.current).call(d3.axisLeft(scale));
  });

  return (
    <g className="axisY" ref={axis} transform={`translate(${left}, ${top})`} />
  );
};

const Rect = ({ data, x, y, height, top, bottom, index}) => {
  return (  
    <g transform={`translate(${x(data.xValue)}, ${y(data.yValue)})`}>
      <rect
        id={`rect-${index}`}
        width={x.bandwidth()}
        height={height - bottom - top - y(data.yValue)}
        fill={colors(data.index)}
      />
      <text
        id={`rect-text-${index}`}
        transform={`translate(${x.bandwidth() / 2}, ${-10}), rotate(-25)`}
        textAnchor="middle"
        alignmentBaseline="baseline"
        fill="grey"
        fontSize="10"
      >
        {format(data.yValue)}
      </text>
    </g>
  );
};

const Bar = props => {
  const [sort, setSort] = useState(false);

  const data = sort
    ? [...props.data].sort((a, b) => a.yValue - b.yValue)
    : [...props.data];
  const x = d3
    .scaleBand()
    .range([0, props.width - props.left - props.right - 30])
    .domain(data.map(d => d.xValue))
    .padding(0.3);

  const y = d3
    .scaleLinear()
    .range([props.height - props.top - props.bottom, 50])
    .domain([0, d3.max(data, d => parseInt(d.yValue) + 5)]);

  // useEffect(() => {
  //   var svg = d3.select("svg")
  //   svg.append("text")
  //   .attr("class", "title")
  //   .attr("x", props.width / 2) //positions it at the middle of the width
  //   .attr("y", props.top) //positions it from the top by the margin top
  //   .attr("font-family", "sans-serif")
  //   .attr("fill", "green")
  //   .attr("text-anchor", "middle")
  //   .text("Chart");
  // });


  return (
    <>
      <Button variant="contained"
              size="small" color="primary"
              onClick={() => { setSort(!sort) }}>
                Toggle sort
      </Button>
      <h3>{props.text}</h3>
      <svg width={props.width} height={props.height} overflow="auto">
        <XAxis
          scale={x}
          top={props.top}
          bottom={props.bottom}
          left={props.left + 20}
          right={props.right + 20}
          height={props.height}
        />
        <YAxis
          scale={y}
          top={props.top}
          bottom={props.bottom}
          left={props.left + 20}
          right={props.right + 20}
        />
        <g className={`g-chart`} transform={`translate(${props.left + 20}, ${props.top})`}>
          {data.map((d, i) => (
            <Rect
              data={d}
              x={x}
              y={y}
              top={props.top}
              bottom={props.bottom}
              height={props.height}
              index={i}
            />
          ))}
        </g>
      </svg>
    </>
  );
};

export default Bar;
