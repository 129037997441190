import React, { useState, useEffect } from 'react'
import ReactWordcloud from "react-wordcloud";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import body_words from "../data/post_word_cloud.json"
import comment_words from "../data/comment_word_cloud.json"


const options = {
  colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
  enableTooltip: true,
  deterministic: false,
  fontFamily: "impact",
  fontSizes: [5, 60],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 1,
  rotations: 3,
  rotationAngles: [0, 90],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};


const WordCloud = () => {
  return (
    <div>
      <h3>Word Cloud from the content of hive blogs</h3>
      <span style={{ height: 400, width: 600 }}>
        <ReactWordcloud options={options} words={body_words} />
      </span>
      <h3>Word Cloud from the content of hive comments</h3>
      <span style={{ height: 400, width: 600 }}>
        <ReactWordcloud options={options} words={comment_words} />
      </span>
    </div>
  );
}

export default WordCloud;