import React from 'react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import '../styles/table.css'

const ManualTab = (props) => {
    const content = `
In the current version of SoChainDB, we provide three different ways to collect our data for various research purposes:

**(1) Downloading pre-processed raw data** ([https://sochaindb.l3s.uni-hannover.de/](https://sochaindb.l3s.uni-hannover.de/)): The limit of pre-processed raw data is that they are only indexed by block IDs and transaction IDs. This way is suitable for the research needs entire dataset (total of about 200-300 GB with the compressed files), which only needs to deal with large-scale data but not focus on data categories.

**(2) Using the APIs:** as described in the paper, the provided APIs are suitable for the research needs of some specific data categories. We could also increase the query limits for you in a short period for you to collect the kinds of data and then set the daily query limit back. 

**(3) Querying our data directly with your Google BigQuerry account** ([https://github.com/SOCHAINDB/hive-db#query-public-dataset-on-bigquery-console](https://github.com/SOCHAINDB/hive-db#query-public-dataset-on-bigquery-console)): Since we also set our database as public, you can also now use your Google BigQuerry account to query any data in our database. This is the most flexible way to collect our data. In contrast, It requires some background in using SQL scripts in Google BigQuery. We also provide some SQL query examples ([https://github.com/SOCHAINDB/hive-db/blob/master/assets/queries.md](https://github.com/SOCHAINDB/hive-db/blob/master/assets/queries.md)). Note that you might need to pay for the query data since it uses your own Google BigQuerry account.
`
    return (
        <ReactMarkdown remarkPlugins={[gfm]} children={content} />
    )
}
export default ManualTab
