import React, { useState, useEffect } from 'react'
import Markdown from 'markdown-to-jsx';
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import '../styles/table.css'

const ApiTab = (props) => {
    const content = `
# Hive Data Service APIs.

## Notes

* We provide Token-Based Authentication to authenticate. **Please add the following key and value to the Request Header:**
    * **TOKEN**: *WrrXP6szu06wlLQVfAM3b0FD8i4612zc*
* The examples of requesting the APIs are using the httpie tool. Please remember [setup environment](https://github.com/SOCHAINDB/hive-db#setup-environment) first.

We provide all APIs under the GET method and separate them into three targets based on users' goals.

The APIs in the early version would only support the Hive blockchain data from **March 27th, 2020** to **July 4th, 2022** the duration is after [Steem Hard Fork](https://www.coindesk.com/steem-hard-fork-hive).

We schedule to add the Steem blockchain data in the subsequent version.

## Target
We will separate the APIs into three targets:
* **Blocks**: get the blocks from the Hive blockchain, which gets all of the transactions of blocks.
* **Posts**: get the posts we filtered from the transactions of the blocks.
* **Comments**: get the comments we filtered from the transactions of the blocks.

## Usage Parameters and supported types
| Parameter | Description | Default | Accepted Values | blocks | posts | comments | statistic |
| - | - | - | - | - | - | - | - |
| size | Limit the results size of a request. A data sample might be large, especially the block samples. Users can set size for reducing runtime.  | 25 | Interger | v | v | v | v |
| [fields](https://github.com/SOCHAINDB/hive-db/blob/master/assets/fields.md) | Get fields in the schema. Not all fields are useful, and it depends on individuals' purposes. Users can add a list of fields for reducing runtime. | "*" | List of strings separated by comma| v | v | v | v |
| witnesses | Filter data by "witnesses." It sometimes is essential information for analyzing. | None | List of strings separated by comma| v | v | v | v |
| ids | Filter data by the identified blocks IDs. | None | List of strings separated by comma  | v | v | v | v |
| block_ids | Filter data by the blocks hash, which is similar to IDs, however, this is used to reference each block in the database. | None | List of strings separated by comma | v | v | v | v |
| [operations](https://github.com/SOCHAINDB/hive-db/blob/master/assets/summary.org#operation-types) | Filter by the operations type of the transactions in the blocks. | None | List of strings separated by comma| v | - | - | - |
| after | Filter data after a specified time. The first available time in our database is at 16:40:09 UTC on 27th March 2020 for the current version. | None | UTC format or timestamp | v | v | v | - |
| before | Filter data before a specified time. The last available time in our database is at 05:29:42 UTC on July 4th, 2022 for the current version. | None | UTC format or timestamp | v | v | v | - |
| authors | Filter by the authors. If users are interested in some posts or comments, they can add a list of authors to search for more actions. | None | List of strings separated by comma | - | v | v | - |
| permlink | Filter by "permlink" being a partition of posts or comments' URL on Hive social network. Users can add a list of "permlinks" for reducing runtime. | None | List of strings separated by comma | - | v | v | - |
| post_permlinks | Filter the comments in the posts having the "permlinks." | None | List of strings separated by comma | - | - | v | - |
| words | Filter the posts or comments which contain the specified input words. This could help users catch some social network trends by searching the hot trending words. | None | List of strings separated by comma | - | v | v | - |
| tags | Filter the posts which contain the specified hashtags. This might help users search the posts more accurately than the words parameter. | None | List of strings separated by comma | - | v | - | - |

## Examples

* We provided some **[APIs for statistics](https://github.com/SOCHAINDB/hive-db#statistics-apis)**.
* For more **[examples of calling the APIs](https://github.com/SOCHAINDB/hive-db#examples-of-the-api-calls)**.
* If you want to make your own queries, you can follow **[this tutorial](https://github.com/SOCHAINDB/hive-db#query-public-dataset-on-bigquery-console)**.

## Citation
~~~js
@inproceedings{nguyen2022sochaindb,
  title={SoChainDB: A Database for Storing and Retrieving Blockchain-Powered Social Network Data},
  author={Nguyen, Hoang H and Bozhkov, Dmytro and Ahmadi, Zahra and Nguyen, Nhat-Minh and Doan, Thanh-Nam},
  booktitle={Proceedings of the 45th International ACM SIGIR Conference on Research and Development in Information Retrieval},
  pages={3036--3045},
  year={2022}
}
~~~

## Appendix
- You can get the list of supported fields of the APIs [here](https://github.com/SOCHAINDB/hive-db/blob/master/assets/fields.md).
- You can get the list of operation types of the APIs [here](https://github.com/CIKM-2021/hive-db/blob/master/assets/summary.org).
- You can see the bigquery SQL examples [here](https://github.com/SOCHAINDB/hive-db/blob/master/assets/queries.md).
`
    return (
        <ReactMarkdown remarkPlugins={[gfm]} children={content} />
    )
}
export default ApiTab
