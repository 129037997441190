import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import axios from 'axios'

import * as d3 from 'd3'
import GithubCorner from 'react-github-corner'
import { Tabs, Tab, Grid, AppBar, Box, Typography, makeStyles} from '@material-ui/core'
// import { TabPanel, TabContext } from '@material-ui/lab';
import Image from 'material-ui-image'
import ReactGA from 'react-ga';

import logo from './logo.svg';
import titleImage from './images/title.png';
import non_avatar from "./images/non_avatar.png"
import sochainlogo from './title.png';

import inputPostData from "./data/top_posts.json"
import inputCommentData from "./data/top_comments.json"

import './App.css'

import Bar from "./charts/Bar.js";
import ManualTab from "./Components/Manual.js"
import ApiTab from "./Components/ApiDocs.js"
import WordCloud from "./Components/WordCloud.js"
import PersonTab from "./Components/AboutUs.js"


ReactGA.initialize('G-B81TVJNBLX');
ReactGA.pageview(window.location.pathname + window.location.search);


const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 5
      }}
  />
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box id={`box-${index}`} bgcolor="white" boxShadow={3} p={3} clone>
          <Typography id={`typography-${index}`}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function App() {
  const tabEnum = Object.freeze({'Home': 0, 'AboutUs': 1, 'APIdocs': 2, 'Database Manual': 3, 'Direct Download': 4});
  const [tabOption, setTabOption] = useState(tabEnum.Home)
  const handleChange = (event, newValue) => {
      const { name, value } = event.target
      console.log(name)
      // material ui tabs
      setTabOption(newValue)
  }
  const formDimensions = {
    xs: 10,
    sm: 10,
    md: 6,
    lg: 6,
    xl: 6,
  };

  var generateData = (input, length = 5) => 
    d3.range(length).map((item, index) => ({
      index: index,
      xValue: input[index].author, 
      yValue: input[index].amount,
    }));

    // const apiEndPoint = "http://localhost:5000/hive-db/v1.0.0/top_posts";
    // axios.get(apiEndPoint, {
    //           headers: {
    //               TOKEN: "WrrXP6szu06wlLQVfAM3b0FD8i4612zc",
    //           }
    //       })
    //       .then((response) => {
    //           console.log('reach here')
    //           console.log(response.data)
    //       })
    //       .catch((error) => {
    //           console.log(error)
    //       })

  const [postData, setPostData] = useState(generateData(inputPostData, 20));
  const [commentData, setCommentData] = useState(generateData(inputCommentData, 40));

  return (
    <div className="App">
      <div>
        <div id='github'>
          <GithubCorner href='https://github.com/CIKM-2021/hive-db' direction='left'/>
        </div>
          {/* <Image src={titleImage} style={{
          width: 50,
          height: 20,
        }}/> */}
      </div>
      <div>
          <Grid container direction="column" alignItems="center" spacing={3}>
              <Grid item>
                  <div className="TabBar">
                    <AppBar position="static">
                        <Tabs value={tabOption} onChange={handleChange} TabIndicatorProps={{ style: { background: "#FF5700" } }} centered aria-label="simple tabs example">
                            <Tab label="Home" {...a11yProps(0)} />
                            <Tab label="About us" {...a11yProps(1)} />
                            <Tab label="API Docs" {...a11yProps(2)} />
                            <Tab label="Database Manual" {...a11yProps(3)} />
                            <Tab label="Direct Download" {...a11yProps(4)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={tabOption} index={0}>
                      <div>
                        <a href='http://sochaindb.com/'>
                        <img id="sochainlogo" src={sochainlogo} alt="Logo"/>
                        </a>
                        <div align='center'>
                          Hoang H. Nguyen, Dmytro Bozhkov, Zahra Ahmadi, Nhat-Minh Nguyen, and Thanh-Nam Doan. 2022. <strong>SoChainDB:</strong> A Database for Storing and Retrieving Blockchain-Powered Social Network Data. In Proceedings of the 45th International ACM SIGIR Conference on Research and Development in Information Retrieval (SIGIR '22). Association for Computing Machinery, New York, NY, USA, 3036-3045. <a href='https://doi.org/10.1145/3477495.3531735'>https://doi.org/10.1145/3477495.3531735</a> 
                        </div>
                      </div>
                      <hr/>
                      <WordCloud />
                      <ColoredLine color="orange"/>
                      <div>
                        <Bar
                          data={postData}
                          width={1000}
                          height={600}
                          top={20}
                          bottom={30}
                          left={30}
                          right={0}
                          text={"The top active authors with the high number of posts"}
                        />
                      </div>
                      <ColoredLine color="orange"/>
                      <div>
                        <Bar
                          data={commentData}
                          width={1000}
                          height={700}
                          top={20}
                          bottom={30}
                          left={30}
                          right={0}
                          text={"The top active authors with the high number of comments"}
                        />
                      </div>
                    </TabPanel>
                    <TabPanel value={tabOption} index={1}>
                      <dev>
                        <h2>Information of our team members.</h2>
                        <PersonTab
                          image={non_avatar}
                          name="Hoang H. Nguyen"
                          email="ehoang@l3s.de"
                          organization="L3S Research Center, Leibniz Universität Hannover"
                          address="Hannover, Germany"
                        />
                        <PersonTab
                          image={non_avatar}
                          name="Dmytro Bozhkov"
                          email="bozhkov@l3s.de"
                          organization="L3S Research Center, Leibniz Universität Hannover"
                          address="Hannover, Germany"
                        />
                          <PersonTab
                            image={non_avatar}
                            name="Ahmadi Zahra"
                            email="zaahmadi@uni-mainz.de"
                            organization="L3S Research Center, Leibniz Universität Hannover"
                            address="Hannover, Germany"
                          />
                        <PersonTab
                          image={non_avatar}
                          name="Nhat-Minh Nguyen"
                          email="nguyenminh180798@gmail.com"
                          organization="Sunshine Tech Ho Chi Minh"
                          address="Ho Chi Minh City, Vietnam"
                        />
                        <PersonTab
                          image={non_avatar}
                          name="Thanh-Nam Doan"
                          email="thanh-nam-doan@utc.edu"
                          organization="University of Tennessee at Chattanooga"
                          address="Chattanooga, TN, USA"
                        />
                      </dev>
                    </TabPanel>
                    <TabPanel value={tabOption} index={2}>
                      <ApiTab></ApiTab>
                    </TabPanel>
                    <TabPanel value={tabOption} index={3}>
                      <ManualTab></ManualTab>
                    </TabPanel>
                    <TabPanel value={tabOption} index={4}>
                      <h3>
                        - We also provide <a href="http://sochaindb.l3s.uni-hannover.de/">direct links</a> for downloading our raw data files.
                      </h3>
                    </TabPanel>
                  </div>
              </Grid>
          </Grid>
        </div>
      
    </div>
  );
}

export default App;
